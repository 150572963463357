import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import TitleScreen from './Home/TitleScreen';
import Team from './Team/Team';
import './base.scss';
import About from './About/About';
import Verify from './Verify/Verify';
import VerifyFail from './VerifyFail/Verify';
import Contact from './Contact/Contact';
import './Navbar/Navbar.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { initTwitter, sendTwitterConversion } from './twitterTracking';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import FAQ from './FAQ/FAQ';
import Privacy from './Privacy/Privacy';
import PrivacyG2 from './PrivacyG2/PrivacyG2';
import News from './News/News';
import Terms from './Terms/Terms';
import BullSharkFAQ from './FAQ/BullsharkFAQ';
import NewPlayerFAQ from './FAQ/NewPlayerFAQ';

const App = () =>
{
  useEffect(() =>
  {
    const gaTrackingID = 'G-048S6RX2DK';
    ReactGA.initialize(gaTrackingID); // TODO: can change the analytics based on the environment!

    // ReactGa.initialize('UA-167321482-1 ');
    // ReactGa.pageview(window.location.pathname);
    initTwitter();
  }, []);

  return (
    <div>
      <TalofaNavbar />

      <div className="background-container">
        <div className="titleSection">
          <TitleScreen />
        </div>
        <div className="newsSection">
          <News />
        </div>

        {/* <Navigation /> */}
        <div className="aboutSection">
          <About />
          <div className="button to-right">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://talofagames.us5.list-manage.com/subscribe?u=514b137ee3cfc15e5c6c38aa9&id=54a65f4aad"
            >
              {' '}
              <span className="link"></span>
            </a>
            <p>Sign Up For The Newsletter</p>
          </div>
          <div className="dash special-dash"></div>
        </div>

        <div className="teamSection">
          <Team />
        </div>
        <div className="contactSection">
          <Contact />
        </div>
      </div>

      {/* <Audio /> */}
    </div>
  );
};

const TalofaNavbar = () =>
{
  return (
    <div className="navbar">
      <Navbar collapseOnSelect bg="dark" expand="lg" variant="dark">
        <Navbar.Brand href="#home">
          <Nav.Link href="#">
            <picture>
              <source srcSet="./Home/logoTransparent.png" type="image/webp" />
              <source srcSet="./Home/logoTransparent.png" type="image/jpeg" />
              <img className="talofa_logo" src="./Home/logoTransparent.png" />
            </picture>
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <Nav.Link href="/faq">FAQ</Nav.Link>
            <Nav.Link href="/privacy">Privacy</Nav.Link>
            <Nav.Link target="_blank" href="https://presskit.talofagames.com/">
              Presskit
            </Nav.Link>
            <Nav.Link href="https://talofagames.com/play">
              Play
            </Nav.Link>

            <Nav.Link href="https://discord.gg/runlegends" target="_blank">
              <Button className="discord-btn">
                {' '}
                <img className="discord-logo" src="./Home/discord-logo.png" />
                Join Discord Community
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

// export class RedirectPage extends React.Component {
//   componentDidMount() {
//     window.location.replace('https://www.google.com')
//   }
//   render() {
//     return <h1>Loading</h1>;
//   }
// }

const RedirectPage = () =>
{
  function getMobileOperatingSystem()
  {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent))
    {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent))
    {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    {
      return "iOS";
    }

    return "unknown";
  }

  React.useEffect(() =>
  {
    sendTwitterConversion();
    let os = getMobileOperatingSystem();
    let downloadLink = '';
    if (os == "Android")
    {
      downloadLink = "https://play.google.com/store/apps/details?id=com.talofagames.abra";
    } else if (os == "iOS")
    {
      downloadLink = "https://apps.apple.com/us/app/run-legends/id1585730648";
    } else
    {
      downloadLink = "https://linktr.ee/talofa";
    }
    console.log(downloadLink, "download link");

    window.location.replace(downloadLink)
  }, [])

  return <div></div>;
}

const AppWrapper = () =>
{
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="faq" element={<FAQ />}>
          </Route>
          <Route path="/verify/success" element={<Verify />} />
          <Route path="/verify/fail" element={<VerifyFail />} />

          <Route path="faq/bullshark" element={<BullSharkFAQ />} />
          <Route path="faq/newplayer" element={<NewPlayerFAQ />} />

          <Route path="privacy" element={<Privacy />} />
          <Route path="privacyg2" element={<PrivacyG2 />} />
          <Route path="terms" element={<Terms />} />
          <Route path='/play' element={<RedirectPage />} />
          {/* <Route path='/external' render={() => window.location = "https://www.google.com"} />
          <Route path="play" element={<Navigate target="_blank" to='https://linktr.ee/talofa' replace={true} />} /> */}
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default AppWrapper;

import React, { useState } from 'react';
import '../base.scss';
import './TitleScreen.scss';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const FormWithToasts = props => {
  return (
    <div className="title-header">
      <img className="legends-logo" src="./Home/runlegendslogo.png" />

      <div className="home-header">
        <div>
          <div className="header-text">
            <p className="tagline">
              A MOBILE FITNESS GAME
              <br />
              THAT MAKES FITNESS FUN!
            </p>
          </div>
          <div>
            <Form>
              <Form.Group controlId="formGroupEmail">
                <div className="waitlist-text">
                  <img className="little-star" src="./Home/littleStar.png" />
                  <p className="title">Run Legends is available now on iOS & Android!</p>
                  {/* <Form.Label className="title">
                    Pre-Order Run Legends!
                  </Form.Label> */}
                  <img className="little-star" src="./Home/littleStar.png" />
                </div>
                {/* <a
                  href="https://apps.apple.com/us/app/run-legends/id1585730648"
                  target="_blank"
                > */}
                <div>
                  <div className="button-apple to-left to-right">
                    <a
                      target="_blank"
                      href="https://apps.apple.com/us/app/run-legends/id1585730648"
                    >
                      {' '}
                      <span className="link"></span>
                    </a>
                  </div>

                  <div className="button-google to-left to-right">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.talofagames.abra"
                    >
                      {' '}
                      <span className="link"></span>
                    </a>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="avatar-container"></div>
      </div>
      {/* <div className="dash"></div> */}
    </div>
  );
};

const TitleScreen = props => {
  return <FormWithToasts onSubmit={props.onSubmit} />;
};

export default TitleScreen;

import React, { useState } from 'react';
import '../base.scss';
import './Contact.scss';

const Contact = props => {
  return (
    <div className="contact section">
      <div className="footer">
        <div className="footer-col follow">
          <p className="footer-header">
            Follow Our
            <br /> Development Journey!
          </p>
        </div>
        <div className="footer-col">
          <p className="footer-header">Get Help</p>

          <a href="mailto:help@talofagames.com">
            <p className="footer-text">Contact Us</p>
          </a>
          <a href="https://discord.gg/runlegends">
            <p className="footer-text">Discord Support</p>
          </a>
        </div>
        <div className="footer-col">
          <p className="footer-header">About Us</p>
          <a
            href="https://www.linkedin.com/company/talofagames"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="footer-text">Careers</p>
          </a>
          <a
            href="https://presskit.talofagames.com/#articles"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="footer-text">PressKit</p>
          </a>
          <a
            href="http://eepurl.com/hI742r"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="footer-text">Newsletter</p>
          </a>
        </div>
        <div className="footer-col">
          <a
            href="https://www.facebook.com/RunLegends"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src="./NewsIcon/facebookIcon.png" />
          </a>
          <a
            href="https://twitter.com/PlayRunLegends?lang=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src="./NewsIcon/twitterIcon.png" />
          </a>
          <a
            href="https://discord.gg/runlegends"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src="./NewsIcon/discordIcon.png" />
          </a>
          <a
            href="https://www.instagram.com/playrunlegends/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src="./NewsIcon/instaIcon.png" />
          </a>
          <a
            href="https://www.tiktok.com/@runlegends"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src="./NewsIcon/tiktokIcon.png" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from 'react';
// import About.scss';
import './Team.scss';
import '../base.scss';

export default function Team() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="about section">
      <p className="title center">WHO WE ARE</p>
      <div className="team-text">
        <p className="section-text center">
          We’re a creative team that loves games and fitness.
        </p>
        <p className="section-text center web-text">
          We want to create new experiences to help players be healthier.
        </p>
        <p className="section-text center">We believe fitness should be fun.</p>
      </div>
      {/* <Slider className="slider" {...settings}>
        <div>
          <div className="team-member">
            <img src="./About/jennyIcon.png" className="team-member-picture" />
            <p className="employee-name">Jenny Xu</p>
          </div>
        </div>
        <div>
          <div className="team-member">
            <img src="./About/amyIcon.png" className="team-member-picture" />
            <p className="employee-name">Amy Green</p>
          </div>
        </div>
        <div>
          <div className="team-member">
            <img src="./About/andyIcon.png" className="team-member-picture" />
            <p className="employee-name">Andy Frank</p>
          </div>
        </div>
        <div>
          <div className="team-member">
            <img src="./About/samIcon.png" className="team-member-picture" />
            <p className="employee-name">Melina Rudianto</p>
          </div>
        </div>
        <div>
          <div className="team-member">
            <img
              src="./About/claytonIcon.png"
              className="team-member-picture"
            />
            <p className="employee-name">Clayton Hunsinger</p>
          </div>
        </div>
        <div>
          <div className="team-member">
            <img src="./About/robertIcon.png" className="team-member-picture" />
            <p className="employee-name">Robert Hornbek</p>
          </div>
        </div>
        <div>
          <div className="team-member">
            <img src="./About/parkerIcon.png" className="team-member-picture" />
            <p className="employee-name">Emma Zhang</p>
          </div>
        </div>
        <div>
          <div className="team-member">
            <img src="./About/mikeIcon.png" className="team-member-picture" />
            <p className="employee-name">Michael Tiller</p>
          </div>
        </div>
        <div>
          <div className="team-member">
            <img src="./About/stephIcon.png" className="team-member-picture" />
            <p className="employee-name">Kalib Crone</p>
          </div>
        </div>
        <div>
          <div className="team-member">
            <img src="./About/melissaIcon.png" className="team-member-picture" />
            <p className="employee-name">Melissa Chen</p>
          </div>
        </div>
      </Slider> */}

      {/* <div className="grid-container">
        <div className="grid">
          <div className="grid-item ">
            <img src="./About/jennyIcon.png" className="team-member-picture" />
            <p className="employee-name">Jenny Xu</p>
          </div>
          <div className="grid-item ">
            <img src="./About/parker.png" className="team-member-picture" />
            <p className="employee-name">Parker Hamilton</p>
          </div>
          <div className="grid-item ">
            <img src="./About/amanda.png" className="team-member-picture" />
            <p className="employee-name">Amanda Harper</p>
          </div>
          <div className="grid-item">
            <img src="./About/john.png" className="team-member-picture" />
            <p className="employee-name">John Xu</p>
          </div>
        </div>
      </div> */}
    </div>
  );
}

import React from 'react';
import '../../components/base.scss';
import './Privacy.css';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const TalofaNavbar = () => {
	return (
		<div className="navbar">
			<Navbar collapseOnSelect bg="dark" expand="lg" variant="dark">
				<Navbar.Brand href="#home">
					<Nav.Link href="/">
						<picture>
							<source srcSet="./Home/logoTransparent.png" type="image/webp" />
							<source srcSet="./Home/logoTransparent.png" type="image/jpeg" />
							<img className="talofa_logo" src="./Home/logoTransparent.png" />
						</picture>
					</Nav.Link>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="mr-auto"></Nav>
					<Nav>
						<Nav.Link href="/faq">FAQ</Nav.Link>
						<Nav.Link href="/privacy">Privacy</Nav.Link>
						<Nav.Link target="_blank" href="https://presskit.talofagames.com/">
							Presskit
						</Nav.Link>

						<Nav.Link href="https://discord.gg/runlegends" target="_blank">
							<Button className="discord-btn">
								{' '}
								<img className="discord-logo" src="./Home/discord-logo.png" />
								Join Discord Community
							</Button>
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

export default function Privacy() {
	return (
		<div className="privacy">
			<div className="background-container">
				<TalofaNavbar />
				<h1>Privacy Policy</h1>

				<p>Posted: 4/24/23</p>

				<p>Your information privacy is important to us. We provide this Privacy Policy to explain how we collect, use, protect, and disclose information and data when you use the Where They Live and Run Legends mobile application (“App”) and other related services offered by us, and any other time you interact with our Company. (“Services”). This Privacy Policy also explains your choices for managing your information preferences, including opting out of certain uses of your Personal Information (defined below). This Privacy Policy applies to all users of the App and Services. The App and Services are provided by Talofa Corporation (“Talofa” “Company” “we” “our” “us”).</p>

				<p>BY USING THE APP OR SERVICES, YOU ARE CONSENTING TO THIS PRIVACY POLICY. PLEASE READ IT CAREFULLY. </p>

				<ol><li><p>Personal Information We Collect</p>

				</li>
				</ol><p>We collect the following categories and types of “Personal Information”: </p>

				<ul>
					<li><p>Contact Information: email address; </p>

					</li>
					<li><p>Other identifying information: device ID, passwords and other security information for authentication and access; </p>

					</li>
					<li><p>Financial Information: credit card, debit card and bank account information; </p>

					</li>
					<li><p>Geolocation information;</p>

					</li>
					<li><p>Commercial information: products or services purchased or viewed on our App; and</p>

					</li>
					<li><p>Internet or other electronic activity: your use history, including information about how you navigate within our App and Services and which elements of our App and Services you use the most.</p>

					</li>
				</ul>

				<ol start="2"><li><p>How We Use Personal Information</p>

				</li>
				</ol><p>We use your Personal Information for the following categories of use:</p>

				<ul>
					<li><p>Transactional Purposes: We use your contact information, other identifying information, financial information, and geolocation information to: </p>

					</li>
				</ul>

				<ul>
					<li><p>Receive, process, confirm, and track your purchases and account status; </p>

					</li>
					<li><p>Communicate with you about your account; and</p>

					</li>
					<li><p>Allow you to use the App and Services.</p>

					</li>
				</ul>
				<ul>
					<li><p>Analytical Purposes: We use your other identifying information, internet and other electronic activity, commercial information, and geolocation data to analyze preferences, trends and statistics.</p>

					</li>
					<li><p>Marketing and Promotional Purposes: We use your contact information, commercial information, internet or other electronic activity, and geolocation information to:</p>

					</li>
				</ul>

				<ul>
					<li><p>Inform you of our new products, services and offerings;</p>

					</li>
					<li><p>Provide you with targeted advertising; </p>

					</li>
					<li><p>Run contests, promotions and sweepstakes; and</p>

					</li>
					<li><p>Provide you with other information from and about our Company, including personalized marketing communications. </p>

					</li>
				</ul>

				<ul>
					<li><p>Maintenance and Improvement of the App and Services: We use your contact information, commercial information, and internet activity and electronic activity to: </p>

					</li>
				</ul>

				<ul>
					<li><p>Provide you with the App and Services, including to send you alerts about your account;</p>

					</li>
					<li><p>Handle your customer services requests; and</p>

					</li>
					<li><p>Help us diagnose technical and service problems and administer our stores, the App, and the Services.</p>

					</li>
				</ul>

				<ul>
					<li><p>Security and Fraud Prevention: We use your contact information, other identifying information, commercial information, financial information, geolocation information, internet activity and electronic activity to protect the App and services, our Company, and others and to prevent fraud, theft and misconduct.</p>

					</li>
				</ul>

				<ol start="3"><li><p>Sources of Personal Information</p>

				</li>
				</ol><p>We collect Personal Information from the following sources: </p>

				<ul>
					<li><p>We collect information directly from you. We collect contact and financial information directly from you. </p>

					</li>
					<li><p>We collect information from you passively. We collect commercial information, other identifying information, geolocation information and internet or other electronic activity passively using tracking technologies. This activity is further described in the Online Tracking sections below.</p>

					</li>
				</ul>

				<ol start="4"><li><p>Categories of Third Parties We Share Personal Information With</p>

				</li>
				</ol><p>Service Providers</p>

				<p>From time to time, we may establish a business relationship with other businesses whom we believe trustworthy and who have confirmed that their privacy practices are consistent with ours (“Service Providers”). For example, we may contract with Service Providers to provide certain services, such as hosting and maintenance, data storage and management, and marketing and promotions. We only provide our Service Providers with the information necessary for them to perform these services on our behalf. Each Service Provider must agree to use reasonable security procedures and practices, appropriate to the nature of the information involved, in order to protect your Personal Information from unauthorized access, use, or disclosure. Service Providers are prohibited from using Personal Information other than as specified by us.</p>

				<p>Our Affiliates</p>

				<p>We may share Personal Information with businesses controlling, controlled by, or under common control with our Company. </p>

				<p>Corporate Transactions</p>

				<p>If our Company is merged, acquired, or sold, or in the event of a transfer of some or all of our assets, we may disclose or transfer Personal Information in connection with such transaction. You will have the opportunity to opt out of any such transfer if, in our discretion, it will result in the handling of your Personal Information in a way that differs materially from this Privacy Policy.</p>

				<p>Compliance with Laws and Law Enforcement</p>

				<p>We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. We may disclose Personal Information and any other information about you to government or law enforcement officials or private parties if, in our discretion, we believe it is necessary or appropriate in order to respond to legal requests (including court orders and subpoenas), to protect the safety, property, or rights of our company or of any third party, to prevent or stop any illegal, unethical, or legally actionable activity, or to comply with the law.</p>

				<ol start="5"><li><p>Online Tracking</p>

				</li>
				</ol><p>We use certain tracking technologies to analyze use of and improve the App and Services. </p>

				<p>We use Google Analytics on the App and Services to analyze how users use the App and Services, and to provide advertisements to you on other websites. For more information about how to opt out of having your information used by Google Analytics, visit <a href="https://tools.google.com/dlpage/gaoptout/" >https://tools.google.com/dlpage/gaoptout/</a>.</p>

				<ol start="6"><li><p>Managing Your Information Preferences</p>

				</li>
				</ol><p>You can opt out of receiving marketing e-mails from us by following the link provided at the bottom of all marketing e-mails you receive from us. You are able to opt out of receiving marketing e-mails from us, however, you cannot opt out of receiving all e-mails from us, such as e-mails about the status of your account. If you have questions or concerns regarding this Privacy Policy, please e-mail us at that same address. </p>

				<ol start="7"><li><p>Be Careful When You Share Information with Others</p>

				</li>
				</ol><p>Please be aware that whenever you share information on any public area of the App or Services, that information may be accessed by others. In addition, please remember that when you share information in any other communications with third parties, that information may be passed along or made public by others. This means that anyone with access to such information can potentially use it for any purpose, including sending unsolicited communications.</p>

				<ol start="8"><li><p>Security</p>

				</li>
				</ol><p>We maintain physical, electronic, and procedural safeguards to protect the confidentiality and security of information transmitted to us. However, no data transmission over the Internet or other network can be guaranteed to be 100% secure. As a result, while we strive to protect information transmitted on or through the App or Services, we cannot and do not guarantee the security of any information you transmit on or through the App or Services, and you do so at your own risk.</p>

				<ol start="9"><li><p>Links</p>

				</li>
				</ol><p>Our App and Services may contain links to other websites or allow others to send you such links. A link to a third party’s website does not mean that we endorse it or that we are affiliated with it. We do not exercise control over third-party websites. You access such third-party websites or content at your own risk. You should always read the privacy policy of a third-party website before providing any information to the website.</p>

				<ol start="10"><li><p>Children’s Privacy</p>

				</li>
				</ol><p>The App and Services are intended for users who are 13 years old or older. We do not knowingly collect Personal Information from children under the age of 13. If we become aware that we have inadvertently received Personal Information from a child under the age of 13, we will delete such information from our records.</p>

				<ol start="11"><li><p>Processing in the United States</p>

				</li>
				</ol><p>Please be aware that your Personal Information and communications may be transferred to and maintained on servers or databases located outside your state, province, or country. If you are located outside of the United States, please be advised that we process and store all information in the United States. The laws in the United States may not be as protective of your privacy as those in your location. By using the App or Services, you are agreeing to the collection, use, transfer, and disclosure of your Personal Information and communications will be governed by the applicable laws in the United States.</p>

				<ol start="12"><li><p>Notice to California Residents/Your California Privacy Rights</p>

				</li>
				</ol><p>We do not share your Personal Information with third parties for their direct marketing purposes.</p>

				<ol start="13"><li><p>Privacy Policy Changes</p>

				</li>
				</ol><p>We may change this Privacy Policy from time to time. If we decide to change this Privacy Policy, we will inform you by posting a link to the revised Privacy Policy on the App. Those changes will go into effect on the “Revised” date shown in the revised Privacy Policy. By continuing to use the App or Services, you are consenting to the revised Privacy Policy.</p>

				<ol start="14"><li><p>Contact Us</p>

				</li>
				</ol><p>If you have any questions or concerns, you may contact us at help@talofagames.com.</p>
				<p>PLEASE PRINT A COPY OF THIS PRIVACY POLICY FOR YOUR RECORDS AND PLEASE CHECK THE APP FREQUENTLY FOR ANY CHANGES.</p>

			</div>
		</div>
	);
}

import React from 'react';
import '../../components/base.scss';
import './Terms.css';
import { Link } from 'react-router-dom';
export default function Terms() {
    return (
        <div className="privacy">
            <div className="background-container">
                <Link to="/">
                    <button className="button">Back</button>
                </Link>
                <p><h1>Run Legends Terms of Use</h1></p>

				<p>Posted: 4/20/23</p>

				<p>These Terms of Use contain an arbitration provision and class action waiver.  Please review the Arbitration section for details. </p>

				<p>These Run Legends Terms of Use (the "Terms") are a legal agreement between Talofa Corporation ("Talofa," "Company," "we," "our," "us") and you, the user, governing your access to and use of: (a) all content and functionalities available on the Run Legends mobile application (the "App") and related services to which these terms are applied (collectively, the "Services"). </p>
                
                <p>THIS IS A LEGALLY BINDING CONTRACT. BY ACCESSING OR USING THE SERVICES OR BY INDICATING YOUR ACCEPTANCE OF THESE TERMS, YOU ACKNOWLEDGE AND REPRESENT THAT YOU HAVE READ THESE TERMS, UNDERSTAND THEM, AND AGREE TO BE BOUND BY THEM. YOU ALSO REPRESENT AND WARRANT THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS CONTRACT AND ARE NOT PROHIBITED BY LAW FROM ACCESSING OR USING THE SERVICES. YOU DO NOT HAVE TO ENTER INTO THESE TERMS, BUT IF YOU DO NOT, YOU WILL NOT BE PERMITTED TO, AND YOU MAY NOT, USE THE SERVICES.</p>

				<p><h2>Overview</h2>  The App offers a free-to-play, strategic fitness battle game. The gamified fitness experience requires you to go outside and move your body to play.  Certain features and aspects of the gameplay utilize NFTs (as such term is defined below).  To learn more about the specifics of the gameplay, and how certain choices you make with your NFT(s) may affect your position in the game, please see the details set forth in the game website. </p>

				<p> <h2>Eligibility</h2> To use the Services, you must be the greater of: (i) 18 years old; or (ii) the age of majority in your state or jurisdiction of residence; or if you are under 18 years of age but are at least 13 years old (A "Minor"), that you are using the Site with the consent of your parent or legal guardian and you have received your parent or legal guardian's permission to use the App and agree to its Terms.  Further, you represent and warrant that you (i) are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a terrorist-supporting country; and (ii) are not listed on any U.S. Government list of prohibited or restricted parties. You may not use the Services if they are prohibited or unlawful in your jurisdiction. </p>

				<p><h2>Your Account</h2>  Certain aspects of the Services may require you to create an account, including by: (i) setting up a App approved wallet that ("Wallet") with all necessary legal authorization to use that Wallet; and/or (ii) completing a registration form and selecting a user ID and password (your "Account").  You agree that the information you provide as part of the registration process will be true, current and complete, and you agree to update such information as applicable so that it continues to be true, current and complete. Your Account is personal to you and may not be used by any other person. You alone are responsible  for use of and access to your Account. You agree to contact us at help@talofagames.com if you become aware of any unauthorized use of the Services on your Account or through your Wallet. </p>

				<p><h2>Gameplay Terms</h2></p>

				<p><h2>Gameplay</h2> Run Legends is a fitness battle game.  More specific gameplay information may be provided in the App. While you are using our Services, please be aware of your surroundings, and play and communicate safely. Talofa does not intend Run Legends to provide medical or health advice.  </p>

				<p>Rewards  You may be able to earn incentives or rewards through the gameplay, including, for example, experience points (collectively, "Rewards").  These are "account bound" rewards, meaning that they remain with your Account and are generally not transferrable.  That is, you cannot sell or trade individual Rewards.  However, you may be able to redeem Rewards as explained in the App. Once redeemed, Rewards will be deleted from your Account.  Subject to your compliance with these terms, you are granted a limited, non-transferable (except as specifically set out in this section), revocable license to use Rewards only for your personal entertainment, in connection with the Services.  Your license to use Rewards automatically terminates when these Terms or your Account terminates for any reason.  To learn more about the specifics of gameplay, and how you may redeem rewards, please see the details set forth in the App.</p>

				<p>NFTs:  You may be able to earn or purchase NFTs through the App.  For clarity, "NFTs" are non-fungible tokens, ownership of which are registered on a blockchain.  NFTs are associated with digital objects (which may include images and/or other digital works) ("Digital Object(s)").  Except as expressly set forth herein and in the Owner Agreement (defined below), the purchase or acquisition of an NFT does not grant the acquirer ownership, or any other rights, to the Digital Object(s). Any license to use the Digital Object is set forth in the Owner Agreement.   NFT Owners may be able to upgrade, transfer or otherwise use their NFT(s) through use of the Services.  You understand and agree that taking certain actions with respect to your NFT may cause a transfer of ownership of your NFT, an alteration or degradation to your NFT, or may cause your NFT to cease to exist.  To learn more about the specifics of the gameplay, and how certain choices you make with your NFT(s) may affect the availability or your ownership of the NFT(s), please see the details set forth in the App.</p>

				<p>NFT Sales:  The App does not include an NFT marketplace, but you may be able to sell your NFT(s) on a third-party NFT marketplace (each, an "NFT Marketplace") subject to the applicable NFT Marketplace's terms and conditions. You understand and agree that the NFT Marketplace is a Third Party Platform (as such term is defined below) and Company is not responsible or liable to you for any transactions on such platform.  Any sale(s) of the NFT(s) will be subject to a ten percent (10%) "Resale Royalty."  The smart contract associated with the NFT is programmed to collect and automatically pay to us the Resale Royalty from the gross proceeds of any sale amount.  </p>

				<p><h2>Additional Terms</h2></p>

				<p>Some of our Services have additional terms and conditions ("Additional Terms").  Where Additional Terms apply to a Service, we will make them available for you to read through prior to your use of that Service.  By using that Service, you agree to the Additional Terms.  You may be required to accept the terms to use that Service. </p>

				<p><h2>Acceptable Use of the App and Services</h2></p>

				<p>You may use the App and Service for your personal use only. You are responsible for your use of the App and Services, and for any use of the Services made using your Account or wallet.  Our goal is to create a positive, useful, legal and safe user experience.  To promote this goal, we prohibit certain kinds of conduct that may be harmful to other users or to us.  When you use the Services, you may not:</p>

				<p>• violate any law or regulation;</p>

				<p>• violate, infringe, or misappropriate other people's intellectual property, privacy, publicity, or other legal rights;</p>

				<p>• post or share anything that is illegal, abusive, harassing, harmful to reputation, pornographic, indecent, profane, obscene, hateful, racist, or otherwise objectionable;</p>

				<p>• send unsolicited or unauthorized advertising or commercial communications, such as spam;</p>

				<p>• engage in spidering or harvesting, or participate in the use of software, including spyware, designed to collect data from the Services;</p>

				<p>• transmit any viruses or other computer instructions or technological means whose purpose is to disrupt, damage, or interfere with the use of computers or related systems;</p>

				<p>• stalk, harass, or harm another individual;</p>

				<p>• impersonate any person or entity or perform any other similar fraudulent activity, such as phishing;</p>

				<p>• use any means to scrape or crawl any Web pages contained in the App;</p>

				<p>• attempt to circumvent any technological measure implemented by us or any of our providers or any other third party (including another user) to protect the Services;</p>

				<p>• attempt to decipher, decompile, disassemble, or reverse engineer any of the software or other underlying code used to provide the Services; or</p>

				<p>• advocate, encourage, or assist any third party in doing any of the foregoing.</p>

				<p><h2>Access to App</h2></p>

				<p>If you download the App from the Apple Store or Google Play App Store, subject to your compliance with these Terms and the Apple Store or Google Play App Store terms, Talofa  hereby grants you a non-exclusive, non-transferable, worldwide, royalty-free, limited-term right to access the App solely for personal, non-commercial use on a single mobile device owned or otherwise controlled by you, strictly in accordance with the App's documentation and these Terms.</p>

				<p>As between the parties, Talofa owns all right, title and interest in and to the App, and any intellectual property rights associated with it. Talofa reserves all rights in and to the App not expressly granted to you in these Terms. Except as expressly permitted by these Terms, by law, or by applicable third party license, you must not and must not allow any third party to: (i) sublicense, sell, rent, lease, transfer, assign, or redistribute the App; (ii) host the App for the benefit of third parties; (iii) disclose or permit any third party to access the App, except as expressly permitted in these Terms; (iv) modify or create derivative works of the App, or merge the App with other software; (v) disassemble, decompile, bypass any code obfuscation, or otherwise reverse engineer the App or attempt to derive any of its source code, in whole or in part; (vi) modify, obscure, or delete any proprietary rights notices included in or on the App; (vii) otherwise use or copy the App in a manner not expressly permitted by these Terms; or (viii) use the App beyond its applicable term.</p>

				<p>By using the App, you acknowledge that this section of the Terms is entered into by and between Talofa and not with Apple, Inc. or Google, Inc. Notwithstanding the foregoing, you acknowledge that Apple, Inc. and its subsidiaries are third-party beneficiaries of this section and that Apple, Inc. and Google, Inc. have the right (and is deemed to have accepted the right) to enforce this section. Talofa is solely responsible for the App and any content contained therein. You acknowledge that Apple, Inc. and Google, Inc. have no obligation whatsoever to furnish any maintenance and support services with respect to the App. You represent and warrant that (i) You are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and (ii) You are not listed on any U.S. Government "watch list" of prohibited or restricted parties, including the Specially Designated Nationals list published by the Office of Foreign Assets Control of the U.S. Treasury or the Denied Persons List published by the U.S. Department of Commerce.  </p>

				<p>In the event of any third party claim that the App or your possession and use of the App infringes that third party's intellectual property rights, Talofa, not Apple, Inc. or Google, Inc., will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.</p>

				<p>This section 7 only applies to the extent that you have access to the App. Upon deletion of the App from your mobile device, all rights granted to you in this section will also terminate, and you must cease use of the App and delete all copies of the App from your mobile device and account. Termination will not limit any of Talofa's rights or remedies at law or in equity. </p>

				<p><h2>Ownership of the Services</h2> </p>

				<p>We (or our licensors) own all right, title, and interest in and to (a) the App and Services, including all software, text, media, and other content available in the App and Services ("Our Content"); and (b) our trademarks, logos, and brand elements ("Marks").  The App and Services, Our Content, and Marks are all protected under U.S. and international laws.  The look and feel of the App and Services are copyright © Talofa. All rights reserved.  You may not duplicate, copy, or reuse any portion of the App, including any visual design elements or concepts without express written permission from us.</p>

				<p><h2>Privacy</h2></p>

				<p>Your privacy is very important to us.  Our Privacy Policy explains how we collect, use, protect, and when we share personal information and other data with others.  You are responsible for maintaining the confidentiality of your Account information, including your private password. You are responsible for all activities that occur under your Account and you agree to notify us immediately of any unauthorized access or use of your Account.  We are not responsible or liable for any damage or loss related to any unauthorized access or use of your Account.</p>

				<p><h2>Third Party Platforms and Links</h2></p>

				<p>The App and Services may contain links to other websites and online resources solely for convenience.  A link to a third party's website does not mean that we endorse it or that we are affiliated with it.  We are not responsible or liable for any damage or loss related to the use of any third-party website.  You should always read the terms and conditions and privacy policy of a third-party website before using it.  </p>

				<p>We do not provide, nor have custody or control over, any third party platforms you are interacting with, including without limitation, wallets, blockchains, NFT marketplaces, communication channels or other third party platforms or services (collectively, "Third Party Platforms"). You are solely responsible for any transaction you conduct using a Third Party Platform, and we are not responsible or liable for any action or inaction by the third party nor any damage or loss related to your use of any Third Party Platform.  You should always read the terms and conditions and privacy policy of a Third Party Platform before using it. Please note that Third Party Platforms, including without limitation, blockchains, may charge fees associated with their respective services (e.g., gas fees).</p>

				<p><h2>Changes to the Services</h2></p>

				<p>We enhance and update the App and Services often.  We may change or discontinue the Services at any time, with or without prior notice to you.</p>

				<p><h2>Termination</h2></p>

				<p>We reserve the right to not provide the Services to any person.  We also reserve the right to terminate any user's right to access the Services at any time, at our discretion.  If you violate any of these Terms, your permission to use the App and Services may be revoked. </p>

				<p><h2>NO MEDICAL ADVICE</h2></p>

				<p>IF YOU HAVE ANY MEDICAL OR HEALTH-RELATED QUESTIONS, CONSULT WITH A MEDICAL PROFESSIONAL AS SOON AS POSSIBLE. IF YOU EXPERIENCE A MEDICAL EMERGENCY, PLEASE CALL 911.</p>

				<p>YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE NOT INTENDED TO, AND DO NOT, PROVIDE MEDICAL ADVICE, DIAGNOSIS OR TREATMENT ADVICE (COLLECTIVELY, "MEDICAL ADVICE"). ALL INFORMATION AND CONTENT PROVIDED THROUGH THE SERVICES, INCLUDING WITHOUT LIMITATION TEXT, IMAGES, GRAPHICS, AUDIO, OR VIDEO, IS FOR INFORMATIONAL PURPOSES ONLY AND IS NOT INTENDED TO BE MEDICAL ADVICE OR A SUBSTITUTE FOR MEDICAL ADVICE. THE INFORMATION AND CONTENT PROVIDED THROUGH THE SERVICES IS NOT INTENDED TO DIAGNOSE, TREAT, CURE, OR PREVENT ANY DISEASE. TALOFA IS NOT RESPONSIBLE FOR ANY HEALTH OR MEDICAL PROBLEMS THAT MAY RESULT FROM YOUR USE OF THE SERVICES. YOU AGREE THAT YOU ARE VOLUNTARILY USING THE APP AND PARTICIPATING IN THE SERVICES AND DO SO AT YOUR OWN RISK AND TO THE EXTENT REQUIRED OR DEEMED NECESSARY, YOU HAVE CONSULTED WITH YOUR HEALTHCARE PROFESSIONALS (OR WILL CONTINUE TO DO SO) WITH REGARDS TO THE USAGE OF THE SERVICES.</p>

				<p><h2>Disclaimer and Limitations on Our Liability</h2></p>

				<p>YOU USE THE APP AND SERVICES AT YOUR OWN RISK.  THE APP AND SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR COMPANY AND ITS OFFICERS, EMPLOYEES, DIRECTORS, SHAREHOLDERS, PARENTS, SUBSIDIARIES, AFFILIATES, AGENTS, CONSULTANTS, SUPPLIERS, AND LICENSORS ("AFFILIATES") DISCLAIM ALL WARRANTIES, CONDITIONS, AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING THOSE RELATED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT AND THOSE ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.  </p>

				<p>IN PARTICULAR, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR COMPANY AND ITS AFFILIATES MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY OR COMPLETENESS OF CONTENT AVAILABLE ON OR THROUGH THE SERVICES, OR THE CONTENT OF ANY WEBSITES OR ONLINE SERVICES LINKED TO OR INTEGRATED WITH THE SERVICES.  OUR COMPANY AND ITS AFFILIATES WILL HAVE NO LIABILITY FOR ANY:  (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (b) PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICES; (c) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS OR OF ANY PERSONAL INFORMATION OR USER DATA; (d) ANY INTERRUPTION OF TRANSMISSION TO OR FROM THE SERVICES; (e) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED ON OR THROUGH THE SERVICES BY ANY THIRD PARTY; OR (f) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED OR SHARED THROUGH THE SERVICES. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, COMPANY MAKES NO WARRANTY THAT THE APP AND SERVICES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. FURTHER, COMPANY MAKES NO WARRANTY REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY INFORMATION OR CONTENT ON THE SERVICES.</p>

				<p>YOU UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ANY MATERIAL OR INFORMATION DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE ARISING FROM DOING SO.  NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE.</p>

				<p>THE NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE APPLICABLE BLOCKCHAIN NETWORK. ANY TRANSACTION THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN SUCH BLOCKCHAIN NETWORK, WHICH COMPANY DOES NOT CONTROL. YOU UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, TALOFA CORPORATION WILL NOT BE RESPONSIBLE FOR ANY COMMUNICATION FAILURES, DISRUPTIONS, ERRORS, DISTORTIONS OR DELAYS YOU MAY EXPERIENCE WHEN EFFECTING NFT TRANSACTIONS, TO THE EXTENT CAUSED BY OR RELATED TO THIRD-PARTY TECHNOLOGY, PLATFORMS, SERVICES OR OTHER FUNCTIONALITY, INCLUDING WITHOUT LIMITATION ANY WALLETS, SMART CONTRACTS, BLOCKCHAINS OR FILE STORAGE SYSTEMS. </p>

				<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES (INCLUDING FOR LOSS OF PROFITS, REVENUE, OR DATA) OR FOR THE COST OF OBTAINING SUBSTITUTE PRODUCTS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS, HOWEVER CAUSED, WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WHETHER OR NOT WE'VE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  </p>

				<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR TOTAL CUMULATIVE LIABILITY TO YOU OR ANY THIRD PARTY UNDER THESE TERMS, FROM ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY, WILL BE LIMITED TO AND WILL NOT EXCEED ONE HUNDRED DOLLARS ($100).</p>

				<p>You understand and agree that we have set our fees and entered into these Terms with you in reliance upon the limitations of liability set forth in these Terms, which allocate risk between us and form the basis of a bargain between the parties. </p>

				<p><h2>Indemnification</h2></p>

				<p>To the maximum extent permitted by applicable law, you agree to indemnify and hold harmless our company and its Affiliates from and against any and all claims, costs, proceedings, demands, losses, damages, and expenses (including, without limitation, reasonable attorney's fees and legal costs) of any kind or nature, relating to, any actual or alleged breach of these Terms by you or anyone using your Account or wallet.  If we assume the defense of such a matter, you will reasonably cooperate with us in such defense.</p>

				<p><h2>Arbitration Agreement &amp; Waiver of Certain Rights</h2></p>

				<p>Except as set forth below, you and we agree that we will resolve any disputes between us (including any disputes between you and a third-party agent of ours) through binding and final arbitration instead of through court proceedings.  You and we hereby waive any right to a jury trial of any Claim (defined below).  All controversies, claims, counterclaims, or other disputes between you and us or you and a third-party agent of ours (each a "Claim") shall be submitted for binding arbitration in accordance with the Rules of the American Arbitration Association ("AAA Rules").  The arbitration will be heard and determined by a single arbitrator.  The arbitrator's decision in any such arbitration will be final and binding upon the parties and may be enforced in any court of competent jurisdiction.  You and we agree that the arbitration proceedings will be kept confidential and that the existence of the proceeding and any element of it (including, without limitation, any pleadings, briefs or other documents submitted or exchanged and any testimony or other oral submissions and awards) will not be disclosed beyond the arbitration proceedings, except as may lawfully be required in judicial proceedings relating to the arbitration, by applicable disclosure rules and regulations of securities regulatory authorities or other governmental agencies, or as specifically permitted by state law.  The Federal Arbitration Act and federal arbitration law apply to this agreement.  However, the Arbitrator, and not any federal, state, or local court or agency, shall have the exclusive authority to resolve any dispute relating to the interpretation, applicability, enforceability, or formation of these Terms including, but not limited to, a claim that all or any part of these Terms is void or voidable.</p>

				<p>If you demonstrate that the costs of arbitration will be prohibitive as compared to the costs of litigation, we will pay as much of the administrative costs and arbitrator's fees required for the arbitration as the arbitrator deems necessary to prevent the cost of the arbitration from being prohibitive.  In the final award, the arbitrator may apportion the costs of arbitration and the compensation of the arbitrator among the parties in such amounts as the arbitrator deems appropriate. </p>

				<p>Notwithstanding any other provision of this agreement, this arbitration agreement does not preclude either party from seeking action by federal, state, or local government agencies.  You and we also have the right to bring qualifying claims in small claims court.  In addition, you and we retain the right to apply to any court of competent jurisdiction for equitable or provisional relief, including pre-arbitral attachments or preliminary injunctions, and any such request shall not be deemed incompatible with these Terms, nor a waiver of the right to have disputes submitted to arbitration as provided in these Terms.</p>

				<p>Neither you nor we may act as a class representative or private attorney general, nor participate as a member of a class of claimants, with respect to any Claim.  Claims may not be arbitrated on a class or representative basis.  The arbitrator can decide only your and/or our individual Claims.  The arbitrator may not consolidate or join the claims of other persons or parties who may be similarly situated.  The arbitrator may award in the arbitration the same damages or other relief available under applicable law, including injunctive and declaratory relief, as if the action were brought in court on an individual basis.  Notwithstanding anything to the contrary in the foregoing or herein, the arbitrator may not issue a "public injunction" and any such "public injunction" may be awarded only by a federal or state court.  If either party seeks a "public injunction," all other claims and prayers for relief must be adjudicated in arbitration first and any prayer or claim for a "public injunction" in federal or state court stayed until the arbitration is completed, after which the federal or state court can adjudicate the party's claim or prayer for "public injunctive relief."  In doing so, the federal or state court is bound under principles of claim or issue preclusion by the decision of the arbitrator.</p>

				<p>If any provision of this Section is found to be invalid or unenforceable, then that specific provision shall be of no force and effect and shall be severed, but the remainder of this Section shall continue in full force and effect.  No waiver of any provision of this Section of the Terms will be effective or enforceable unless recorded in a writing signed by the party waiving such a right or requirement.  Such a waiver shall not waive or affect any other portion of these Terms.  This Section of the Terms will survive the termination of your relationship with us.</p>

				<p>THIS SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE RIGHT TO MAINTAIN A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE RIGHT TO PARTICIPATE IN ANY FORM OF CLASS OR REPRESENTATIVE CLAIM, THE RIGHT TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED IN AAA RULES, AND THE RIGHT TO CERTAIN REMEDIES AND FORMS OF RELIEF.  OTHER RIGHTS THAT YOU OR WE WOULD HAVE IN COURT ALSO MAY NOT BE AVAILABLE IN ARBITRATION.</p>

				<p><h2>Other Provisions</h2></p>

				<p>Under no circumstances will we be held liable for any delay or failure in performance due in whole or in part to any acts of nature or other causes beyond our reasonable control.</p>

				<p>These Terms will be governed by and construed in accordance with the laws of the State of California without giving effect to any conflict of laws, rules or provisions.  </p>

				<p>Without altering the arbitration requirement set forth above, in the event any action of whatever nature relating to these Terms, the App, or Services must be filed in a court, we mutually agree that such action may only be filed in the state or federal courts located in San Jose, California.  You consent and submit to the personal jurisdiction of such courts for the purposes of any such action and agree not to challenge the personal jurisdiction or convenience of the forum. </p>

				<p>If any provision of these Terms is found to be unlawful or unenforceable, then that provision will be deemed severable from these Terms and will not affect the enforceability of any other provisions.  </p>

				<p>The failure by us to enforce any right or provision of these Terms will not prevent us from enforcing such right or provision in the future.</p>

				<p>We may assign our rights and obligations under these Terms, including in connection with a merger, acquisition, sale of assets or equity, or by operation of law.</p>

				<p><h2>Changes to these Terms</h2></p>

				<p>From time to time, we may change these Terms.  If we change these Terms, we will post a notice of the changes in the app and a direct link to the latest version of the terms for your review     .  Those changes will go into effect on the Revision Date shown in the revised Terms.  By continuing to use the Services, you are agreeing to the revised Terms.  You may be required to indicate acceptance of the changed Terms to continue use of the Services. </p>

				<p>PLEASE PRINT A COPY OF THESE TERMS FOR YOU RECORDS AND PLEASE CHECK THE APP FREQUENTLY FOR ANY CHANGES TO THESE TERMS.</p>
              
            </div>
        </div>
    );
}

import React, { Suspense } from 'react';
import '../base.scss';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';
import './About.scss';

class About extends React.PureComponent {
  render() {
    // let YoutubeComponent = React.lazy(() =>
    //   import('../YoutubeEmbed/YoutubeEmbed')
    // );
    return (
      <div className="coach section">
        <p></p>
        <p className="title center">AVAILABLE NOW</p>
        <p>Walk or run outside to fight “Sappers” - enemies that represent real-life anxieties. Build mental and physical health as you upgrade your gear, make new friends, and learn more about the characters' backstories. </p>

        <div className="cards">
          <div id="grad1">
            <img src="./About/sword.png" className="responsive-image" />
            <p className="card-title">REAL-TIME BATTLES</p>
            <p className="card-text">
              Walk or run in the real world to play! Set your own pace and tailor the gameplay to your fitness level.
            </p>
          </div>
          <div id="grad1">
            <img src="./About/shield.png" className="responsive-image" />
            <p className="card-title">BATTLE, MOVE, WIN!</p>
            <p className="card-text">
              Step up your cardio with gameplay mechanics inspired by high-intensity interval training.
            </p>
          </div>
          <div id="grad1">
            <img src="./About/wand.png" className="responsive-image" />
            <p className="card-title">UPGRADE AND EARN</p>
            <p className="card-text">
              Equip and upgrade a variety of Gear to take down Sappers and earn
              rewards through Operations and leaderboards.
            </p>
          </div>
        </div>
        <div className="video-wrapper">
          <YoutubeEmbed embedId="vQQtFe0vNfA" />
        </div>
      </div>
    );
  }
}
export default About;

import React from 'react';
import '../../components/base.scss';
// import { Link } from 'react-router-dom';

// import './Navbar/Navbar.scss';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


const TalofaNavbar = () =>
{
    return (
        <div className="navbar">
            <Navbar collapseOnSelect bg="dark" expand="lg" variant="dark">
                <Navbar.Brand href="#home">
                    <Nav.Link href="/">
                        <picture>
                            <source srcSet="../Home/logoTransparent.png" type="image/webp" />
                            <source srcSet="../Home/logoTransparent.png" type="image/jpeg" />
                            <img className="talofa_logo" src="../Home/logoTransparent.png" />
                        </picture>
                    </Nav.Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    <Nav>
                        <Nav.Link href="/faq">FAQ</Nav.Link>
                        <Nav.Link href="/privacy">Privacy</Nav.Link>
                        <Nav.Link target="_blank" href="https://presskit.talofagames.com/">
                            Presskit
                        </Nav.Link>

                        <Nav.Link href="https://discord.gg/runlegends" target="_blank">
                            <Button className="discord-btn">
                                {' '}
                                <img className="discord-logo" src="../Home/discord-logo.png" />
                                Join Discord Community
                            </Button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default function VerifyFail()
{
    return (
        <div className="faq">
            <TalofaNavbar />

            <div className="background-container">
                <h1>Email Failed to Verify!</h1>
                <p>There was an error when verifying your email. Try again in the mobile app.</p>
                <p>View some commonly asked questions below.</p>
                <div className="faq-container">
                    <div className="left-column">
                        <div className="faq-section">
                            <div>
                                <h2>I'm a new player, how to I learn how to play? </h2>
                                <p>Go to this page by <a href="https://www.talofagames.com/faq/newplayer">clicking here</a> to learn about how to get started with Run Legends.
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>What should I know before playing? </h2>
                                <p>Run Legends is a gamified fitness experience that will require you go outside and move your body to play.

                                    You do not need to be a skilled runner, or to run at all if you prefer to just walk, but you will need space to move around.

                                    For your safety, make sure you are dressed appropriately with supportive shoes, and in a place you feel comfortable.
                                </p>
                            </div>
                        </div>

                        <div className="faq-section">
                            <div>
                                <h2>How do I get Run Legends?</h2>
                                <p>
                                    {' '}
                                    Download the game by <a href="https://www.talofagames.com/play">clicking here</a> and you’ll be directed to the direct download links!

                                    If you do not already have access to the official Discord, you can <a href="https://discord.gg/runlegends">click here</a> to join.

                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>How much does it cost to play? </h2>
                                <p>Run Legends is a free to play experience and a free to download mobile game.

                                    You do not need to spend money in the game to unlock features.

                                    There is an in-game store where you can purchase items that enhance the experience.
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                          <div>
                            <h2>What happens if I have issues with the game, or want to delete my account?</h2>
                            <p>Join our <a href="https://discord.gg/runlegends">Discord</a> to get in
                              contact with the development team.

                              You can also <a href="mailto:help@talofagames.com">email us at help@talofagames.com</a>.

                              We are the most responsive over Discord, so if you need to urgently reach us, that is the best place to do so.
                            </p>
                          </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>How do I learn more about the game?</h2>
                                <p>Check out our social media pages to learn more about the game!

                                    We are on <a href="https://twitter.com/playrunlegends?lang=en">Twitter</a>, <a href="https://www.facebook.com/RunLegends">Facebook</a>, <a href="https://www.instagram.com/playrunlegends/">Instagram</a>, <a href="https://www.tiktok.com/@runlegends">Tiktok</a>, <a href="https://www.youtube.com/@playrunlegends">Youtube</a> and <a href="https://medium.com/@TalofaGames">Medium</a>.

                                    We are the most responsive over Discord, so if you need to urgently reach us, that is the best place to do so.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

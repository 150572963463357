import React from 'react';
import '../../components/base.scss';
import './FAQ.css';
// import { Link } from 'react-router-dom';

// import './Navbar/Navbar.scss';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const TalofaNavbar = () => {
    return (
        <div className="navbar">
            <Navbar collapseOnSelect bg="dark" expand="lg" variant="dark">
                <Navbar.Brand href="#home">
                    <Nav.Link href="/">
                        <picture>
                            <source srcSet="../Home/logoTransparent.png" type="image/webp" />
                            <source srcSet="./Home/logoTransparent.png" type="image/jpeg" />
                            <img className="talofa_logo" src="../Home/logoTransparent.png" />
                        </picture>
                    </Nav.Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    <Nav>
                        <Nav.Link href="/faq">FAQ</Nav.Link>
                        <Nav.Link href="/privacy">Privacy</Nav.Link>
                        <Nav.Link target="_blank" href="https://presskit.talofagames.com/">
                            Presskit
                        </Nav.Link>

                        <Nav.Link href="https://discord.gg/runlegends" target="_blank">
                            <Button className="discord-btn">
                                {' '}
                                <img className="discord-logo" src="../Home/discord-logo.png" />
                                Join Discord Community
                            </Button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default function BullSharkFAQ() {
    return (
        <div className="faq">
            <TalofaNavbar />

            <div className="background-container">
                <h1>Wallet Linking FAQ</h1>
                
                <p>Note: Please only use official links, double check before connecting wallets, and beware of scams!</p>
                <div className="faq-container">
                    <div className="left-column">
                        <div className='faq-buttons'>
                            <a href="/faq">
                                <button
                                    className="button faq-button"
                                >
                                    Main FAQ
                                </button>
                            </a>
                            <a href="/faq/newplayer">

                                <button
                                    className="button"
                                // onClick={handleSubmitLogin}
                                >
                                    New Player FAQ
                                </button>
                            </a>
                        </div>

                        <div className="faq-section">
                            <div>
                                <h2>How do I create an account?</h2>
                                <p>When you complete the tutorial, the game automatically creates a placeholder account for you - go to Menu and then click Save Progress to complete the process! You can set your own username and email address.
                                    <p><img className="bullshark-faq-img" src="../NewPlayer/6.png" /></p>
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>How do I join the Run Legends Bullshark Quest from the web portal?</h2>
                                <p> You can watch the video here or read the instructions below </p>
                                <p><iframe width="420" height="315"
src="https://www.youtube.com/embed/ntwqmqx3dHo">
</iframe></p>
                                <p>Follow the instructions below:
                                    <ol type="1">
                                        <li> Make sure you’ve created an account in the Run Legends game - instructions for creating an account and <a target="_blank" href="https://www.talofagames.com/faq/newplayer">how to play the game are listed here</a> </li>
                                        <li> Then, go to the <a target="_blank" href="https://runlegends.talofagames.com/">Run Legends portal</a> (make sure you join on web, the portal will not work on mobile), connect your wallet (we recommend using Ethos Wallet). Login to your Run Legend account and click on the Bullshark Tab.</li>
                                        <img className="bullshark-faq-img" src="../Bullshark/0.png" />

                                        <li>If you are eligible to join the Quest, you can click the Bullshark on the screen and join the quest.</li>
                                        <img className="bullshark-faq-img" src="../Bullshark/1.png" />

                                        <li>You’ll be gifted an Aqua Shield NFT that you get for participating in the Quest, click “Participate” to claim it.</li>
                                        <img className="bullshark-faq-img" src="../Bullshark/2.png" />

                                        <li> You’re now in the Quest!</li>
                                        <img className="bullshark-faq-img" src="../Bullshark/3.png" />
                                    </ol>
                                </p>
                            </div>
                        </div>

                        <div className="faq-section">
                            <div>
                                <h2>How long will the Bullshark Quest Season be active? </h2>
                                <p>Bullshark Quest Season 3 ended on November 9, 2023.
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>My Aqua Shield is not showing up in my in-game inventory!</h2>
                                <p>First try to open then close the game and complete a mission. If your gear hasn’t appeared you will need to clear your app’s cache. A simple way to do this is to delete the game, then redownload it. If you still can’t see your shield after trying this, please reach out to us for further support.
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>How do I see how many enemies I’ve defeated in the game?</h2>
                                <p>If you click on “Info” on your Aqua Shield, you’ll see how many enemies you’ve defeated since joining the Bullshark Quest. Note: You do not need to defeat enemies with the Aqua Shield to earn points in the Quest. You can use any Gear you want to defeat enemies.
                                </p>
                                <img className="bullshark-faq-img" src="../Bullshark/4.png" />
                                <img className="bullshark-faq-img" src="../Bullshark/5.png" />


                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>Who do I contact if I need support?</h2>
                                <p>We have two options for getting support with the game:

                                    <p> 1. If you encounter a bug, report directly from the app by tapping the three lines in the menu and then tapping Report Bug. This will generate an email and automatically attach your logs so that the devs can take a look at what might be causing any issues. Please remember to tell us in the body of the email what your issue is.
                                    </p><img className="bullshark-faq-img" src="../Bullshark/settings.jpg" /><p> 2. Reach out in the <a target="_blank" href="https://discord.gg/runlegends">Discord server here</a> and go to the #open-ticket channel. From there, you can explain your issue and a staff member will respond within one business day.
                                    </p>
                                </p>
                            </div>
                        </div>
                        <h1>Troubleshooting</h1>
                        <div className="faq-section">
                            <div>
                                <h2>Can I connect the same wallet to different game accounts?</h2>
                                <p>No, one non-custodial wallet can only be used to connect to ONE game account. You cannot connect multiple accounts to the same wallet.
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>Can I connect different game wallets to the same account?</h2>
                                <p>Yes. We allow you to switch wallets. But the future score will belong to the new Bullshark.
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>The portal isn’t responding when I hit “Participate”</h2>
                                <p>Try to log out and log back into your game account.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from 'react';
// import About.scss';
import './News.scss';
import '../base.scss';
// import 'bootstrap/dist/css/bootstrap.css';

export default function News() {
  return (
    <div className="news section">
      <a
        target="_blank"
        href="https://www.digitaltrends.com/gaming/run-leneds-hands-on-impressions-beta/"
      >
        <picture>
          <source srcset="/NewsIcon/dtw.webp" type="image/webp" />
          <source srcset="/NewsIcon/dtw.png" type="image/png" />
          <img className="large-news-icon" src="./NewsIcon/dtw.png" />
        </picture>

        {/* <img className="large-news-icon" src="./NewsIcon/dtw.png" /> */}
      </a>
      <a
        target="_blank"
        href="https://www.pcmag.com/news/call-of-duty-or-lego-star-wars-5-games-coming-to-iphone-ipad-and-mac-in"
      >
        <picture>
          <source srcset="/NewsIcon/PCMag.webp" type="image/webp" />
          <source srcset="/NewsIcon/PCMag.png" type="image/png" />
          <img className="news-icon" src="./NewsIcon/PCMag.png" />
        </picture>
        {/* <img className="news-icon" src="./NewsIcon/PCMag.png" /> */}
      </a>
      <a
        target="_blank"
        href="https://www.shacknews.com/article/134242/everything-seen-gaming-at-apple-dev-showcase"
      >
        <picture>
          <source srcset="/NewsIcon/shack.webp" type="image/webp" />
          <source srcset="/NewsIcon/shack.png" type="image/png" />
          <img
            className="large-news-icon large-icon"
            src="./NewsIcon/shack.png"
          />
        </picture>
        {/* <img
          className="large-news-icon large-icon"
          src="./NewsIcon/shack.png"
        /> */}
      </a>
      <a
        target="_blank"
        href="https://9to5mac.com/2023/02/17/apple-event-gaming-iphone-mac/"
      >
        <picture>
          <source srcset="/NewsIcon/925.webp" type="image/webp" />
          <source srcset="/NewsIcon/925.png" type="image/png" />
          <img
            className="large-news-icon low-news large-icon"
            src="./NewsIcon/925.png"
          />{' '}
        </picture>
        {/* <img
          className="large-news-icon low-news large-icon"
          src="./NewsIcon/925.png"
        /> */}
      </a>
      <a
        target="_blank"
        href="https://screenrant.com/apple-dev-showcase-biggest-news-honkai-warzone-mobile/"
      >
        <picture>
          <source srcset="/NewsIcon/screenrant.webp" type="image/webp" />
          <source srcset="/NewsIcon/screenrant.png" type="image/png" />
          <img
            className="large-news-icon large-icon"
            src="./NewsIcon/screenrant.png"
          />{' '}
        </picture>
      </a>
    </div>
  );
}

import React from 'react';
import '../../components/base.scss';
import './FAQ.css';
// import { Link } from 'react-router-dom';

// import './Navbar/Navbar.scss';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const TalofaNavbar = () => {
    return (
        <div className="navbar">
            <Navbar collapseOnSelect bg="dark" expand="lg" variant="dark">
                <Navbar.Brand href="#home">
                    <Nav.Link href="/">
                        <picture>
                            <source srcSet="../Home/logoTransparent.png" type="image/webp" />
                            <source srcSet="../Home/logoTransparent.png" type="image/jpeg" />
                            <img className="talofa_logo" src="../Home/logoTransparent.png" />
                        </picture>
                    </Nav.Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    <Nav>
                        <Nav.Link href="/faq">FAQ</Nav.Link>
                        <Nav.Link href="/privacy">Privacy</Nav.Link>
                        <Nav.Link target="_blank" href="https://presskit.talofagames.com/">
                            Presskit
                        </Nav.Link>

                        <Nav.Link href="https://discord.gg/runlegends" target="_blank">
                            <Button className="discord-btn">
                                {' '}
                                <img className="discord-logo" src="../Home/discord-logo.png" />
                                Join Discord Community
                            </Button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default function NewPlayerFAQ() {
    return (
        <div className="faq">
            <TalofaNavbar />

            <div className="background-container">
                <h1>New Player FAQ</h1>
                
                <p>Note: Please only use official links, double check before connecting wallets, and beware of scams!</p>
                <div className="faq-container">
                    <div className="left-column">
                        <div className='faq-buttons'>

                            <a href="/faq">
                                <button
                                    className="button faq-button"
                                >
                                    Main FAQ
                                </button>
                            </a>
                            <a href="/faq/bullshark">

                                <button
                                    className="button"
                                // onClick={handleSubmitLogin}
                                >
                                    Bullshark FAQ
                                </button>
                            </a>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>Where can I download Run Legends? </h2>
                                <p>Run Legends is available for download in the <a target="_blank" href="https://play.google.com/store/apps/details?id=com.talofagames.abra">Google Play store</a> and <a target="_blank" href="https://apps.apple.com/us/app/run-legends/id1585730648">Apple App Store</a> Run Legends will not run on a PC emulator.
                                </p>
                            </div>
                        </div>
                        
                        <div className="faq-section">
                            <div>
                                <h2>How do I create an account?</h2>
                                <p>When you complete the tutorial, the game automatically creates a placeholder account for you - go to Menu and then click Save Progress to complete the process! You can set your own username and email address.
                                    <p><img className="bullshark-faq-img" src="../NewPlayer/6.png" /></p>
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>How should I prepare for playing the game?</h2>
                                <p>
                                    Run Legends is a gamified fitness experience that will require you to go outside and move your body to play! You do not need to be a skilled runner, or to run at all if you prefer to just walk, but you will need space to move around.
                                    <p></p>
                                    <p>Make sure to:</p>
                                    <li>Turn the WiFi off on your phone and use data. </li>
                                    <li>Enable location permissions and GPS for the app.</li>
                                    <li> Dress appropriately with supportive shoes and clothing that’s easy to move in. </li>
                                    <li>Run only in a place where you feel comfortable and safe.</li>
                                    <li> Run Legends is best experienced with headphones, but maintain awareness of your surroundings!</li>


                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>Is there a tutorial? </h2>
                                <p>Yes! When you first launch the game, you will have the opportunity to play through a tutorial that takes you through your first mission as a Runnegade, as well as showing you how to collect daily rewards. You can also replay the tutorial by going to the Missions tab.

                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>How does playing the game work? </h2>
                                <p>To play the game, start by setting your baseline pace! You can stroll, walk, or run. Once this is set and you’ve started a mission, your pace will determine which skill you use against any enemies you encounter. Speed up and slow down to change your skill, and maintain that pace to charge it up.

                                </p>
                            </div>
                        </div>

                        <div className="faq-section">
                            <div>
                                <h2>How do I set my baseline pace?</h2>
                                <p>You’ll be prompted to set your baseline pace before you play the tutorial, but if you need to reset it or find it again, simply tap your baseline on the main screen. From there, you can set it manually or complete a calibration mission.
                                    <p><img className="bullshark-faq-img" src="../NewPlayer/1.jpg" />
                                    </p><p><img className="bullshark-faq-img" src="../NewPlayer/2.png" /></p>
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>How do you navigate the home page?</h2>
                                <p>The home page and menu function as shown here:
                                    <p><img className="bullshark-faq-img" src="../NewPlayer/3.jpg" /></p>
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>How do you navigate the mission screen?</h2>
                                <p>The mission screen functions as shown below:
                                    <p><img className="bullshark-faq-img" src="../NewPlayer/4.jpg" /></p>
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>What does everything in the gear menu mean?</h2>
                                <p>The gear menu is where you can see the gear and items you’ve crafted or collected, and where you can upgrade gear using fragments.
                                    <p><img className="bullshark-faq-img" src="../NewPlayer/5.jpg" /></p>
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>How do I collect daily rewards?</h2>
                                <p>When you first launch the game every day, you’ll get a pop-up that allows you to see and collect your daily rewards, check daily operations, and access your step counter!
                                    <p><img className="bullshark-faq-img" src="../NewPlayer/7.png" /></p>
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2> How do I play with my friends?</h2>
                                <p>After you’ve tapped “PLAY” on the Mission screen and selected your mission, you’ll see a preparation screen like the one below - click either of the “Invite” buttons on either side of your gear icon to generate a unique code to share with your friends! All they have to do is tap “JOIN” from their own Mission screen and enter the code. Up to three players can join one mission at a time.
                                    <p> <img className="bullshark-faq-img" src="../NewPlayer/8.png" /></p>
                                </p>
                            </div>
                        </div>
                        <div className="faq-section">
                            <div>
                                <h2>Who do I contact if I need support?</h2>
                                <p>We have two options for getting support with the game:

                                    <p> 1. If you encounter a bug, report directly from the app by tapping the three lines in the menu and then tapping Contact Support. This will generate an email and automatically attach your logs so that the devs can take a look at what might be causing any issues. Please remember to tell us in the body of the email what your issue is.
                                    </p><img className="bullshark-faq-img" src="../NewPlayer/9.png" /><p> 2. Reach out in the <a target="_blank" href="https://discord.gg/runlegends">Discord server here</a> and go to the #open-ticket channel. From there, you can explain your issue and a staff member will respond within one business day.
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
